<template>
  <div class="page-wrap">
    <div class="session-form-hold">
      <base-card>
        <v-progress-linear
          :active="loading"
          :indeterminate="loading"
          absolute
          top
          color="primary"
        />
        <v-card-text class="text-center">
          <v-avatar
            tile
            size=""
            class="mb-4"
          >
            <!-- <img
              src="https://assets.octillion.tv/images/Octillion_Image_Requirements_1020x852_A1.png"
              alt="Octillion Logo"
            >-->
            <img
              src="/login.png"
              alt="Octillion Logo"
            >
          </v-avatar>

          <h6 class="text--disabled font-weight-medium mb-5">
            Administration Console
          </h6>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
          >
            <v-text-field
              v-model="email"
              label="Email"
              :rules="emailRules"
              required
            />

            <v-text-field
              v-model="ePassword"
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show ? 'text' : 'password'"
              name="input-10-2"
              label="Password"
              class="mt-2"
              :rules="passwordRules"
              required
              @click:append="show = !show"
            />
            <v-checkbox
              v-if="false"
              v-model="checkbox1"
              label="Remember this computer"
              color="secondary"
            />
            <v-btn
              class="mb-4 mt-4"
              block
              color="success"
              dark
              @click="formSubmit"
            >
              <v-icon left>
                mdi-login
              </v-icon>
              Sign In
            </v-btn>
            <v-btn
              v-if="false"
              block
              color="secondary"
              class="mb-4 mt-4"
              @click="monitor()"
            >
              Monitoring
            </v-btn>
            <div
              v-if="false"
              class="d-flex justify-around flex-wrap"
            >
              <v-btn
                text
                small
                color="secondary"
                class="mb-2"
              >
                Forgot Password
              </v-btn>
              <v-btn
                text
                small
                color="secondary"
                to="/app/sessions/sign-up-2"
              >
                Create New Account
              </v-btn>
            </div>
          </v-form>
        </v-card-text>
      </base-card>
      <div class="text-center">
        <v-snackbar          
          v-model="snackbar_show"
          :timeout="timeout"
          absolute
          top
          color="warning"
          :multi-line="true"
        >
          {{ snackbar_message }}
        </v-snackbar>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "Login",
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: "Login",
  },
  data() {
    return {
      timeout: 5000,
      snackbar_show: false,
      snackbar_message: "",
      valid: true,
      show: false,
      password: "Password",
      checkbox1: true,
      checkbox2: false,
      email: "",
      ePassword: "",
      loading: false,
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      passwordRules: [
        (v) => !!v || "Password is required", 
      ],
    };
  },
  computed: {
    ...mapGetters(["loggedInUser", "error", "getThemeMode"]),
  },
  watch: {
    loggedInUser(val) {
      if (val && val.active === true) {
        this.loading = true;
        setTimeout(() => {
          this.$router.push("/");
        }, 500);
      }
    },
    error(val) {
      if (val != null) {
        this.snackbar_show = true;
        this.snackbar_message = `Invalid Email or Password, please try again.`;
      }
    },
  },
  methods: {
    ...mapActions(["login", "signOut"]),
    formSubmit() {
      const valid = this.$refs.form.validate();
      if (valid) this.login({ email: this.email, password: this.ePassword });
    },
    monitor() {
      this.$router.push("/monitor");
    }
  },
};
</script>
<style lang="scss" scoped>
.page-wrap {
  background-color: #201751 !important;
  display: flex;
  align-items: center;
  padding: 40px 1rem;
  height: 100%;
  min-height: 100vh;
  // background-image: url("https://assets.octillionmedia.com/logo-without-text.png");
  // background-size: cover;
}
.logo_size {
  height: 150px !important;
}
.session-form-hold {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}
</style>
